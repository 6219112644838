exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mdx-fields-slugurl-js-content-file-path-src-content-about-me-mdx": () => import("./../../../src/pages/{Mdx.fields__slugurl}.js?__contentFilePath=/opt/build/repo/src/content/about-me.mdx" /* webpackChunkName: "component---src-pages-mdx-fields-slugurl-js-content-file-path-src-content-about-me-mdx" */),
  "component---src-pages-mdx-fields-slugurl-js-content-file-path-src-content-aboutme-2-mdx": () => import("./../../../src/pages/{Mdx.fields__slugurl}.js?__contentFilePath=/opt/build/repo/src/content/aboutme2.mdx" /* webpackChunkName: "component---src-pages-mdx-fields-slugurl-js-content-file-path-src-content-aboutme-2-mdx" */),
  "component---src-pages-mdx-fields-slugurl-js-content-file-path-src-pages-case-studies-archer-higher-education-landing-pages-mdx": () => import("./../../../src/pages/{Mdx.fields__slugurl}.js?__contentFilePath=/opt/build/repo/src/pages/case-studies/archer-higher-education-landing-pages.mdx" /* webpackChunkName: "component---src-pages-mdx-fields-slugurl-js-content-file-path-src-pages-case-studies-archer-higher-education-landing-pages-mdx" */),
  "component---src-pages-mdx-fields-slugurl-js-content-file-path-src-pages-case-studies-archer-higher-education-website-mdx": () => import("./../../../src/pages/{Mdx.fields__slugurl}.js?__contentFilePath=/opt/build/repo/src/pages/case-studies/archer-higher-education-website.mdx" /* webpackChunkName: "component---src-pages-mdx-fields-slugurl-js-content-file-path-src-pages-case-studies-archer-higher-education-website-mdx" */),
  "component---src-pages-mdx-fields-slugurl-js-content-file-path-src-pages-case-studies-bettercloud-elevate-strategy-mdx": () => import("./../../../src/pages/{Mdx.fields__slugurl}.js?__contentFilePath=/opt/build/repo/src/pages/case-studies/bettercloud-elevate-strategy.mdx" /* webpackChunkName: "component---src-pages-mdx-fields-slugurl-js-content-file-path-src-pages-case-studies-bettercloud-elevate-strategy-mdx" */),
  "component---src-pages-mdx-fields-slugurl-js-content-file-path-src-pages-case-studies-bettercloud-secure-elevate-mdx": () => import("./../../../src/pages/{Mdx.fields__slugurl}.js?__contentFilePath=/opt/build/repo/src/pages/case-studies/bettercloud-secure-elevate.mdx" /* webpackChunkName: "component---src-pages-mdx-fields-slugurl-js-content-file-path-src-pages-case-studies-bettercloud-secure-elevate-mdx" */),
  "component---src-pages-mdx-fields-slugurl-js-content-file-path-src-pages-case-studies-healthcare-learning-management-system-mdx": () => import("./../../../src/pages/{Mdx.fields__slugurl}.js?__contentFilePath=/opt/build/repo/src/pages/case-studies/healthcare-learning-management-system.mdx" /* webpackChunkName: "component---src-pages-mdx-fields-slugurl-js-content-file-path-src-pages-case-studies-healthcare-learning-management-system-mdx" */),
  "component---src-pages-mdx-fields-slugurl-js-content-file-path-src-pages-experience-mdx": () => import("./../../../src/pages/{Mdx.fields__slugurl}.js?__contentFilePath=/opt/build/repo/src/pages/experience.mdx" /* webpackChunkName: "component---src-pages-mdx-fields-slugurl-js-content-file-path-src-pages-experience-mdx" */)
}

